.selectBlock {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  border: black 1px solid;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  min-width: 200px;
}

.text {
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
}

.button > *:not(:last-child) {
  margin-right: 1rem;
}

.tableElement {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.round {
  margin: 0 1rem;
  border: black 1px solid;
  padding: 1rem;
  text-align: center;
  border-radius: 8px;
  min-width: 200px;
}
