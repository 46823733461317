div,
h1,
h2 {
  box-sizing: border-box;
}

.table {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
  margin: 1rem;
}

.App {
  display: flex;
  /*overflow: hidden;*/
  /*height: 100vh;*/
  /*width: 100vw;*/
}
.text {
  text-align: center;
}

.menu {
  min-width: 200px;
  max-width: 200px;
}

.content {
  /*overflow: scroll;*/
  display: flex;
  flex-direction: column;
  align-items: center;
}
